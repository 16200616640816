import ChevronDown from '@components/svgs/svgChevronDown';
import styles from '@styles/default-page-scss/hero-banner/_hero-banner-promo.module.scss';
import Image from 'next/image';

type Props = {
    label: [] | [{ gtm_label: string; path: string; url: string }];
    h1Title: string;
    desc: string;
};

export default function heroBannerPromo({ label, h1Title, desc }: Props): JSX.Element {
    return (
        <>
            <section className={styles.PromoSection}>
                <>
                    {label.length > 0 ? (
                        <a
                            href={label[0].url}
                            className={styles.promoImage}
                            title="See Exclusive Deals"
                            data-event-category="User Action"
                            data-event-action="default-promo-thumb-mobile"
                            data-event-label={label[0].url}>
                            <Image
                                unoptimized
                                alt={`Promo thumbnail`}
                                src={'https://assets.rabbitsreviews.com/' + `${label[0].path}`}
                                layout={'fill'}
                                objectFit="cover"
                                priority={true}
                            />
                        </a>
                    ) : (
                        ''
                    )}
                    <div className={label.length > 0 ? styles.heading : styles.headingOnly}>
                        <h1 className={styles.title}>{h1Title}</h1>
                        <hr />
                        <p className={styles.description}>{desc}</p>
                        <div className={styles.readMoreSection}>
                            <a href="#intro-content" className={styles.readMoreButton}>
                                Read More
                            </a>
                            <ChevronDown color="#216600" height={11} width={11} />{' '}
                        </div>
                    </div>
                </>
            </section>
        </>
    );
}
